import themeSettingsCommons from './common';
import type ITheme from './interfaces';

const darkTheme: ITheme = {
  title: 'DARK_THEME',
  colors: {
    background: '#000000',
    heading: '#FAFAFA',
    title: '#FAFAFA',
    highlightColor: '#3052f6',
    button: '#FAFAFA',
    buttonTextColor: '#000000',
    icon: '#ABAFB4',
    text: '#ABAFB4',
    headerColor: 'rgba(0, 0, 0, 0.85)',
    headerHoverColor: '#181818',
    headerBottomColor: '#181818',
    commandBarColor: '#000000',
    codeBlockColor: 'rgba(0, 0, 0, 0.85)',
    borderColor: '#181818',
    cardColor: '#181818',
  },
  ...themeSettingsCommons,
} as const;

export default darkTheme;
