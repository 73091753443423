import themeSettingsCommons from './common';
import type ITheme from './interfaces';

const lightTheme: ITheme = {
  title: 'LIGHT_THEME',
  colors: {
    background: 'rgba(255, 255, 255)',
    heading: '#09090B',
    title: '#09090B',
    highlightColor: '#3052f6',
    button: '#18181b',
    buttonTextColor: '#fafafa',
    icon: '#666666',
    text: '#666666',
    headerColor: 'rgba(255, 255, 255)',
    headerHoverColor: '#eeeeee',
    headerBottomColor: '#F4F4F5',
    commandBarColor: '#ffffff',
    codeBlockColor: 'hsla(0,0%,100%,.8)',
    borderColor: '#E4E4E7',
    cardColor: '#F4F4F5',
  },
  ...themeSettingsCommons,
} as const;

export default lightTheme;
